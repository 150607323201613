import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from 'docz';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "heading"
    }}>{`Heading`}</h1>
    <p>{`The Heading is used for all the Headings across the app.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Heading} mdxType="Props" />
Props table might not render due to a [bug in docz](https://github.com/pedronauck/docz/issues/777)
    <p>{`The main props to set are:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`as`}</strong>{` - tag and level of heading to use. `}<inlineCode parentName="li">{`h1`}</inlineCode>{` - `}<inlineCode parentName="li">{`h6`}</inlineCode>{` defaults to `}<inlineCode parentName="li">{`h2`}</inlineCode></li>
    </ul>
    <p>{`Make sure you only have one h1 per page.`}</p>
    <p>{`If you need more options, consider using `}<a parentName="p" {...{
        "href": "components/Text"
      }}>{`Text`}</a>{` or `}<a parentName="p" {...{
        "href": "components/Paragraph"
      }}>{`Paragraph`}</a>{`.`}</p>
    <Playground __position={1} __code={'<Heading as=\"h1\">The big site title, just one per page, please</Heading>\n<Heading>What a default!</Heading>\n<Heading as=\"h3\">You will use this one a lot</Heading>\n<Paragraph>\n  Lorem ipsum dolor amet photo booth scenester cornhole trust fund vaporware\n  williamsburg. Selfies tbh tumeric XOXO man braid cred. Skateboard heirloom\n  locavore, kogi everyday carry af tattooed art party asymmetrical cardigan\n  sustainable. Tbh cornhole post-ironic, literally hashtag ethical adaptogen\n  brooklyn bushwick distillery.\n</Paragraph>\n<Heading as=\"h4\">And this guy</Heading>\n<Paragraph>\n  Tbh cornhole post-ironic, literally hashtag ethical adaptogen brooklyn\n  bushwick distillery. Vape stumptown swag glossier small batch gastropub.\n  Vape stumptown swag glossier small batch gastropub. Taxidermy 90\'s everyday\n  carry kombucha.\n</Paragraph>\n<Heading as=\"h5\">From here, we\'re using neutral colors</Heading>\n<Paragraph>\n  Everyday carry af tattooed art party asymmetrical cardigan sustainable. Tbh\n  cornhole post-ironic, literally hashtag ethical adaptogen brooklyn bushwick\n  distillery. Vape stumptown swag glossier small batch gastropub. Taxidermy\n  90\'s everyday carry kombucha. Banjo VHS occupy marfa roof party slow-carb.\n</Paragraph>\n<Heading as=\"h6\">All the way down to the smallest one</Heading>\n<Paragraph>\n  Skateboard heirloom locavore, kogi everyday carry af tattooed art party\n  asymmetrical cardigan sustainable. Tbh cornhole post-ironic, literally\n  hashtag ethical adaptogen brooklyn bushwick distillery. Vape stumptown swag\n  glossier small batch gastropub. Taxidermy 90\'s everyday carry kombucha.\n  Banjo VHS occupy marfa roof party slow-carb.\n</Paragraph>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Heading,
      Paragraph,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Heading as="h1" mdxType="Heading">The big site title, just one per page, please</Heading>
  <Heading mdxType="Heading">What a default!</Heading>
  <Heading as="h3" mdxType="Heading">You will use this one a lot</Heading>
  <Paragraph mdxType="Paragraph">
    Lorem ipsum dolor amet photo booth scenester cornhole trust fund vaporware
    williamsburg. Selfies tbh tumeric XOXO man braid cred. Skateboard heirloom
    locavore, kogi everyday carry af tattooed art party asymmetrical cardigan
    sustainable. Tbh cornhole post-ironic, literally hashtag ethical adaptogen
    brooklyn bushwick distillery.
  </Paragraph>
  <Heading as="h4" mdxType="Heading">And this guy</Heading>
  <Paragraph mdxType="Paragraph">
    Tbh cornhole post-ironic, literally hashtag ethical adaptogen brooklyn
    bushwick distillery. Vape stumptown swag glossier small batch gastropub.
    Vape stumptown swag glossier small batch gastropub. Taxidermy 90's everyday
    carry kombucha.
  </Paragraph>
  <Heading as="h5" mdxType="Heading">From here, we're using neutral colors</Heading>
  <Paragraph mdxType="Paragraph">
    Everyday carry af tattooed art party asymmetrical cardigan sustainable. Tbh
    cornhole post-ironic, literally hashtag ethical adaptogen brooklyn bushwick
    distillery. Vape stumptown swag glossier small batch gastropub. Taxidermy
    90's everyday carry kombucha. Banjo VHS occupy marfa roof party slow-carb.
  </Paragraph>
  <Heading as="h6" mdxType="Heading">All the way down to the smallest one</Heading>
  <Paragraph mdxType="Paragraph">
    Skateboard heirloom locavore, kogi everyday carry af tattooed art party
    asymmetrical cardigan sustainable. Tbh cornhole post-ironic, literally
    hashtag ethical adaptogen brooklyn bushwick distillery. Vape stumptown swag
    glossier small batch gastropub. Taxidermy 90's everyday carry kombucha.
    Banjo VHS occupy marfa roof party slow-carb.
  </Paragraph>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      